<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="6">
                    <v-card class="pa-0">
                        <v-toolbar short flat color="red white--text">
                            <v-toolbar-title>Delete nameserver group</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text v-if="nameserverGroup">
                            <p class="text-overline mt-2 mb-0">Name</p>
                            <p>{{ nameserverGroup.label }}</p>

                            <p class="text-overline mt-2 mb-0">Linked domains</p>
                            <p v-if="Array.isArray(domainList)">{{ domainList.length }}</p>
                            <p v-if="!Array.isArray(domainList)">Data not available</p>

                            <p class="text-overline mb-0 mt-4" :class="{ 'grey--text': isDeleteEnabled ? false : true }">Confirmation</p>

                            <p :class="{ 'grey--text': isDeleteEnabled ? false : true }">Tap on the button below to delete this nameserver group.</p>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1" fixed-width></font-awesome-icon>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                                <!-- <font-awesome-icon :icon="['fas', 'info-circle']" class="white--text text-body-1 ml-2" fixed-width></font-awesome-icon> -->
                            </v-alert>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete the nameserver group" :disabled="!isDeleteEnabled"></v-checkbox>

                            <v-btn color="red white--text" @click="deleteNameserverGroup" :disabled="!isDeleteEnabled || !isDeleteConfirmed">Delete</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        nameserverGroup: null,
        domainList: null,
        deleteConfirmation1: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            focus: (state) => state.focus,
        }),
        isDeleteEnabled() {
            return Array.isArray(this.domainList) && this.domainList.length === 0;
        },
        isDeleteConfirmed() {
            return this.deleteConfirmation1;
        },
    },
    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.loadNameserverGroup();
                    this.loadNameserverGroupDomainCount();
                }, 1);
            });
        },
    },
    methods: {
        async loadNameserverGroup() {
            try {
                this.$store.commit('loading', { loadNameserverGroup: true });
                const response = await this.$client.main().nameserverGroup.get({ id: this.$route.query.id });
                this.nameserverGroup = response.item;
            } catch (err) {
                console.error('failed to load nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load nameserver group' });
            } finally {
                this.$store.commit('loading', { loadNameserverGroup: false });
                this.isViewReady = true;
            }
        },
        async loadNameserverGroupDomainCount() {
            try {
                this.$store.commit('loading', { loadNameserverGroupDomainCount: true });
                const response = await this.$client.main().system.getDomainList({ nameserver_group_id: this.$route.query.id }); // TODO: get a count instead of a list, we just need to know if it's non-zero; then we can link to another page where user can do the search and see the domains, with pagination
                this.domainList = response.list;
            } catch (err) {
                console.error('failed to load nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load nameserver group' });
            } finally {
                this.$store.commit('loading', { loadNameserverGroupDomainCount: false });
                this.isViewReady = true;
            }
        },
        async deleteNameserverGroup() {
            try {
                this.$store.commit('loading', { deleteNameserverGroup: true });
                const response = await this.$client.main().nameserverGroup.delete({ id: this.$route.query.id });
                if (response?.status?.isDeleted) {
                    if (this.$route.query.afterDeleteGoBack === 2) {
                        this.$router.go(-2);
                    } else {
                        this.$router.go(-1);
                    }
                }
            } catch (err) {
                console.error('failed to delete nameserver group', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete nameserver group' });
            } finally {
                this.$store.commit('loading', { deleteNameserverGroup: false });
            }
        },
    },
    mounted() {
        this.loadNameserverGroup();
        this.loadNameserverGroupDomainCount();
    },
};
</script>
